import React from "react";
import '../../css/cursos.css'
import bannerdiseno from '../../img/facultades/diseno.jpg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica";
import panta from '../../img/cursos/panta_diseno.png';
import planeDiseno from '../../img/Plan-estudios/diseno.png';




const Diseno =() => {

    const infoDiseno = [
        {
          Ficha: "El programa de Diseño Gráfico de la Fundación Universitaria Horizonte ofrece una propuesta de valor única al combinar una formación técnica de vanguardia con un enfoque creativo e innovador, integrando el dominio de herramientas digitales avanzadas con el estudio profundo de la teoría del diseño y la estética, preparamos a nuestros estudiantes para destacar en un mercado competitivo. La metodología educativa se basa en proyectos reales, colaboraciones interdisciplinarias y una inmersión constante en las tendencias actuales del diseño, lo que garantiza que nuestros graduados no solo adquieran habilidades prácticas, sino que también desarrollen una visión crítica y estratégica",
          Perfil:"El Diseñador Gráfico es un profesional creativo y analítico, especializado en la creación de soluciones visuales que comunican mensajes de manera efectiva y estética, con una sólida formación en principios de diseño, teoría del color, tipografía y software de diseño, el Diseñador Gráfico se encarga de transformar conceptos abstractos en elementos visuales tangibles que capturan la atención y transmiten el mensaje deseado.",
          PerfilOcupacional:"El Diseñador gráfico de la Fundación Universitaria Horizonte podrá desempeñar como: •	Director Creativo, para diseñar y desarrollar la línea gráfica y corporativa de las organizaciones •	Director del departamento de línea gráfica. •	Coordinador de proyectos creativos e innovadores desde una perspectiva comunicativa, creativa y empresarial.",
          Denominación: "Diseño Gráfico",
          Titulo: "Diseñador Gráfico",
          Modalidad: "Presencial",
          Creditos:"149 Créditos",
          Semestres:"8",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "107917",
          Registro: "002134",
          Matricula: "$5.961.550",
          planEstudio: planeDiseno,
          videoFondo: panta,
          Video: "https://www.youtube.com/embed/lcvTQz85oSI",
          Facultad:"Facultad de comunicación, arte y marketing digital",
          Telefono:"Tel: 7437270 Ext. 130",
          Email: "psdisgrafico@unihorzionte.edu.co",
          certificacion: "Certificación en Modelado 3D",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannerdiseno})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoDiseno.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoDiseno.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default Diseno