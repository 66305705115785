import React from "react";
import '../../css/cursos.css';
import bannergastro from '../../img/facultades/gastro_2.jpg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica";
import panta from '../../img/cursos/panta-gastro.png'
import planegastro from '../../img/Plan-estudios/gastronomia.png'


const Derecho =() => {

    const infoDerecho = [
        {
          Ficha: "Ser un profesional llamado a salvaguardar el patrimonio gastronómico, a dar un nuevo significado a los productos locales y aportar a un sector económico en crecimiento, con una puesta en marcha de la gastronomía sostenible, el arte culinario y la gastronomía con responsabilidad social, HORIZONTE, tu mejor opción.",
          Perfil:"El Profesional en Gastronomía de HORIZONTE es un individuo altamente capacitado, con una sólida formación académica y ética, que respeta profundamente los valores del desarrollo humano, contribuye al avance de la sociedad mediante la innovación en productos y servicios para establecimientos gastronómicos, la optimización en la producción de alimentos y bebidas, la gestión de economatos y el maridaje en la oferta gastronómica. Se destaca por su capacidad reflexiva y crítica en la toma de decisiones, su creatividad e innovación en la resolución de problemas, y su liderazgo en proyectos de desarrollo, sustentado en los sólidos vínculos que establece en sus relaciones interpersonales.",
          PerfilOcupacional:"El profesional de Gastronomía de la fundación universitaria HORIZONTE podrá desempeñarse como: •	Comunicador y/o crítico gastronómico. •	 Gerente de alimentos y bebidas. •	Consultor o investigador gastronómico. •	Líder o asesor de proyectos gastronómicos sustentables. •	 Director de eventos gastronómicos. •	Chef Ejecutivo, Chef Pastelero, Sous chef y Chef de partida en muchos sectores como restaurantes. •	Sommelier, barista. •	Diseñador de alimentos enfocados al hambre cero.",
          Denominación: "Gastronomía",
          Titulo: "Profesional en gastronomía",
          Modalidad: "Presencial",
          Creditos:"135 Créditos",
          Semestres:"8",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "106481",
          Registro: "19679",
          Matricula: "$6.334.500",
          planEstudio: planegastro,
          videoFondo: panta,
          Video: "https://www.youtube.com/embed/u1kLibmjAK8",
          Facultad:"Facultad de gastronomía",
          Telefono:"Tel: 7437270 Ext. 147",
          Email: "direccion.gastronomía@unihorzionte.edu.co",
          certificacion: "Certificación en Diseño de Alimentos",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannergastro})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
               
                  </div>
                  {
                          infoDerecho.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>

              </section>
            
           
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoDerecho.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
           
  </>
    )
}

export default Derecho