import React from "react";
import bannertecgestionadmonfinan from '../../img/facultades/admon_finan.jpeg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica2";
import gastrocurse from '../../img/cursos/razones_tec_gestion_administrativa.png';
import planeTecGestionAdminFinan from '../../img/Plan-estudios/tecnologia-gestion-administrativa-financiera.png';





const TecGestionAdminiFinan =() => {

    const infoTecGestionAdminiFinan  = [
        {
          Ficha: "El Tecnólogo en Gestión Administrativa y Financiera de la Fundación Universitaria Horizonte combina teoría y práctica para formar tecnólogos altamente capacitados en la gestión administrativa y financiera, con habilidades que les permiten enfrentar los desafíos del mundo empresarial con confianza y competencia.", 
          Perfil:"El Tecnólogo en Gestión Administrativa y Financiera es un profesional capacitado para analizar y optimizar procesos administrativos y financieros en diversas organizaciones, preparado para manejar sistemas de información contable y financiera, asegurando la precisión y eficiencia en la gestión de recursos, actúa con un alto sentido ético y de responsabilidad social, promoviendo prácticas empresariales sostenibles y transparentes.",
          PerfilOcupacional:"El Tecnólogo en Gestión Administrativa y Financiera de la Fundación Universitaria Horizonte podrá desempeñarse como: •	Auxiliar o Asistente administrativo y financiero •	Auxiliar o Asistente de Nómina •	Auxiliar o Asistente de Proyectos •	Analista de Créditos •	Analista contable y financiera de las organizaciones.",
          Denominación: "Tecnología en Gestión admnistrativa y financiera",
          Titulo: "Tecnólogo/a en gestión administrativa y financiera",
          Modalidad: "Presencial",
          Creditos:"103 Créditos",
          Semestres:"6",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "109382",
          Registro: "8224",
          Matricula: "$3.298.150",
          planEstudio: planeTecGestionAdminFinan,
          videoFondo: gastrocurse,
          link: "https://www.instagram.com/p/C2Xc0jyvLDF/?img_index=1",
          Facultad:"Facultad de ciencias administrativas",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Certificado en Lenguajes de Programación",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannertecgestionadmonfinan})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoTecGestionAdminiFinan .map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoTecGestionAdminiFinan .map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default TecGestionAdminiFinan