import React from "react";
import banneradmonagro from '../../img/facultades/agraria.jpeg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica2";
import gastrocurse from '../../img/cursos/razones_admon_agro.png';
import planeAdmonAgro from '../../img/Plan-estudios/administracion-agropecuaria.png';

const AdminAgro =() => {

    const infoAdminAgro = [
        {
          Ficha: "El programa de Administración Agropecuaria la Fundación Universitaria Horizonte ofrece una formación integral que combina conocimientos en administración, producción agrícola, y gestión de recursos naturales, desarrollando habilidades prácticas y teóricas que les permiten enfrentar los desafíos del sector agropecuario con visión estratégica, formamos profesionales capaces de identificar oportunidades de negocio y de implementar proyectos que mejoren la calidad de vida en las zonas rurales.",
          Perfil:"El Administrador Agropecuario será un profesional integral, con una sólida formación académica, estará comprometido con el desarrollo sostenible del sector agropecuario, aplicando estrategias innovadoras y soluciones efectivas a las problemáticas del sector, con capacidad para planificar, organizar, dirigir y controlar proyectos agropecuarios, asegurando la eficiencia y sostenibilidad de los recursos.",
          PerfilOcupacional:"El profesional en Administración Agropecuaria de la Fundación Universitaria Horizonte podrá desempeñarse como: •	Administrador o gerente de empresas agropecuarias o agroindustriales •	Gestor y emprendedor del sector agroindustrial y agroalimentario •	Consultor de empresas rurales •	Líder de procesos en producción animal, agrícola y agroindustriales.",
          Denominación: "Administración Agropecuaria",
          Titulo: "Administrador/a Agropecuario/a",
          Modalidad: "Presencial",
          Creditos:"148 Créditos",
          Semestres:"9",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "109386",
          Registro: "9921",
          Matricula: "$5.936.650",
          planEstudio: planeAdmonAgro,
          videoFondo: gastrocurse,
          link: "https://www.instagram.com/p/C2QsPGlR_s0/?img_index=1",
          Facultad:"Facultad de ciencias administrativas",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Con certificación en Productividad Agrícola",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${banneradmonagro})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoAdminAgro.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoAdminAgro.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default AdminAgro