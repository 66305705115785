import React from "react";
import { Link } from "react-router-dom";
import "../carousel/carousel.css";
import Carousel from 'react-bootstrap/Carousel';
import video from "../../../img/hero-carousel/Intro.mp4";
//import img2 from '../../../img/hero-carousel/hero-carousel-3.jpg';
import img3 from '../../../img/hero-carousel/hero-carousel-4.jpg';
//import img1 from '../../../img/hero-carousel/Diplomados2024-1.png';
import img8 from '../../../img/hero-carousel/InscripciondeAsignaturas.jpg';
import img9 from '../../../img/hero-carousel/hero-carousel-9.jpg';
import img2 from '../../../img/hero-carousel/banner_web_homologacion.jpg';
import 'bootstrap-icons/font/bootstrap-icons.css';


const Carouselh = () =>{

  const ScrollToTop = () => {
    window.scrollTo({
      top: (0,0),
      behavior: "smooth",
    });
    return null
  }

  return(
      <Carousel fade>
        <Carousel.Item>
          <video autoPlay muted loop embed-responsive-item="true" controls >
            <source src={video} type="video/mp4" />
          </video>
        </Carousel.Item>
        
{/*       <Carousel.Item>
      <div class="center-btn img-fliud">
        <img src={img1} alt="Diplomados2024" text="" className="img-fliud" />
        <a className=" btn d-flex justify-content-center" href="https://site2.q10.com/SolicitudesInstitucionales/NuevaSolicitud?aplentId=8fea0808-7bab-4370-8802-1e51d36b878c" target="_blank" alt="Buttom">Inscríbete aquí</a>
      </div>
      </Carousel.Item> */}

        
      <Carousel.Item>
      <div class="center-btn img-fliud">
        <img src={img8} alt="Banner principal" text="" className="img-fliud" />
        <a className=" btn d-flex justify-content-center" href=" https://site2.q10.com/login?ReturnUrl=%2F&aplentId=8fea0808-7bab-4370-8802-1e51d36b878c" target="_blank" alt="Buttom">Inscríbete aquí</a>
      </div>
      </Carousel.Item>


        <Carousel.Item>
          <img src={img2} alt="Banner principal" text="First slide" className="img-fliud" />
        </Carousel.Item>



        <Carousel.Item>
          <img src={img3} alt="Banner principal" text="First slide" className="img-fliud" />
        </Carousel.Item>



      </Carousel>
  )
}

export default Carouselh



/*<Carousel.Item>
<a> 
  <Link to="/Contactenos" onClick={ScrollToTop}>
    <img src={img7} alt="Banner principal" text="First slide" className="img-fliud" />
  </Link>
</a>
</Carousel.Item>  
<Carousel.Item>
          <a href="https://FUH.q10.com" target="_blank">
            <img src={img2} alt="Banner principal" text="First slide" className="img-fliud" target="_blank" />
          </a>font-family: var(--font-secondary);
</Carousel.Item>
<Carousel.Item>
  <a href="https://FUH.q10.com/SolicitudesInstitucionales/NuevaSolicitud" target="_blank">
    <img src={img9} alt="Banner principal" text="First slide" className="img-fliud" target="_blank" />
  </a>
</Carousel.Item>

<Carousel.Item>
  <img src={img1} alt="Banner principal" text="First slide" className="img-fliud" />
</Carousel.Item>

<Carousel.Item>
      <div class="center-btn img-fliud">
        <img src={img8} alt="Banner principal" text="" className="img-fliud" />
        <a className=" btn d-flex justify-content-center" href="https://forms.office.com/pages/responsepage.aspx?id=wDpmn8lOGU-ncGUay-HmX_uPEwE45BZJn07EvuwWgSBUMkxZQlhGMU5RNkpSMUU1WEpDSUg4R0JWSC4u" target="_blank" alt="Buttom">Inscríbete aquí</a>
      </div>
</Carousel.Item>*/
